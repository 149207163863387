import React from "react";
import {ArrowStyle} from    "./ArrowStyle"; 

const ArrowAnime = () => {
  return (
    <>
      <svg
        className="animated-arrow"
        width="52"
        height="101"
        viewBox="0 0 52 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="arrow-line"
          d="M26.4751 1.75V82.75"
          stroke="white"
          strokeWidth="3"
          strokeLinecap="round"
        />
        <path
          className="arrow-head"
          d="M35.575 76.9L27.175 85.325C27.075 85.425 26.9667 85.496 26.85 85.538C26.7333 85.58 26.6083 85.6007 26.475 85.6C26.3417 85.6 26.2167 85.5794 26.1 85.538C25.9833 85.4967 25.875 85.4257 25.775 85.325L17.35 76.9C17.1167 76.6667 17 76.375 17 76.025C17 75.675 17.125 75.375 17.375 75.125C17.625 74.875 17.9167 74.75 18.25 74.75C18.5833 74.75 18.875 74.875 19.125 75.125L26.475 82.475L33.825 75.125C34.0583 74.8917 34.346 74.775 34.688 74.775C35.03 74.775 35.3257 74.9 35.575 75.15C35.825 75.4 35.95 75.6917 35.95 76.025C35.95 76.3584 35.825 76.65 35.575 76.9Z"
          fill="white"
        />
        <circle cx="26" cy="74.5" r="25.5" stroke="white" />
      </svg>
      <ArrowStyle/>
    </>
  );
};

export default ArrowAnime;
