import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sectiondata from "../../data/sections.json";
import * as Actions from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { useSort } from "../../utils/useSort";
import { notify } from "../../utils/Notification";
import { Redirect } from "react-router-dom";
import ArrowAnime from "../../utils/ArrowAnime";
import styled from "styled-components";
import SearchBar from "../../Home/SearchBar";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const BannerContainer = styled.div`
  position: relative;
  height: 100vh;
`;

const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
`;

const InnerContainer = styled.div`
  margin-top: 180px;
  padding: 2rem;
`;

const Title = styled.h1`
  color: #fff;
  text-align: center;
  font-size: 40px;
  font-weight: 700;

  margin-bottom: 1rem;
`;

const SubTitle = styled.h5`
  color: #fff;
  text-align: center;
  font-size: 1rem;
  margin-bottom: 2rem;
`;

const MainSearch = styled.div`
  margin-top: 2rem;
`;

const SingleInput = styled.div`
  display: flex;
  align-items: center;
  background-color: #ffffff77;
  color: #000000;
  border-radius: 5px;
  padding: 0.5rem 1rem;
`;

const SearchButton = styled(Link)`
  background-color: #f0c14b;
  color: #000;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #e4b84f;
  }
`;
const ResponsiveContainer = styled.div`
  width: 90%; /* Default size for screens less than md */
  margin: 0 auto; /* Center the content horizontally */

  @media (min-width: 768px) {
    width: 80%; /* Adjust size for screens greater than md */
  }
`;
const HomeBanner = () => {
  const $ = window.$;
  const dispatch = useDispatch();
  const history = useHistory();
  const { reactSelectModifier } = useSort();

  const [city, setCity] = useState("");
  const [period, setPeriod] = useState(0);
  const [typeModified, setTypeModifid] = useState([]);
  const types = useSelector((state) => state.pcat.types);
  const type = useSelector((state) => state.pcat.type);
  const subType = useSelector((state) => state.pcat.subType);
  const subTypes = useSelector((state) => state.pcat.subTypes);
  const loading = useSelector((state) => state.property.loading);

  let data = sectiondata.banner;

  const inlineStyle = {
    backgroundImage: "url(/bg.jpg)",
  };
  const imageStyle = {
    position: "relative",
    height: "inherit",
  };

  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.8)", // Adjust the color and opacity here
  };

  useEffect(() => {
    setTypeModifid(reactSelectModifier(types));
  }, [types]);
  const searchProperty = () => {
    let msg;
    if (!city) {
      msg = "Search box is empty";
    } else {
      dispatch(
        Actions.searchProperty(city, () => {
          console.log("sd");
          history.push("/directory"); // Redirect using history
        })
      );
    }

    if (msg) {
      notify(msg);
    }
  };
  return (
    <BannerContainer>
      <BackgroundImage src="/bg.jpg" alt="Image with Overlay" />
      <Overlay>
        <InnerContainer>
          <Title>
            {data.title1} <br /> {data.title2}
          </Title>
          <SubTitle>
            Embark on a journey to find the perfect haven that resonates with
            your dreams and aspirations. <br /> Our mission is to guide you in
            discovering a home that encapsulates your unique style and
            preferences.
          </SubTitle>
          <ResponsiveContainer>
            <SearchBar
              onClick={() => searchProperty()}
              label={loading ? "Please wait" : "Search"}
              city={city}
              setCity={setCity}
            />
          </ResponsiveContainer>
        </InnerContainer>
      </Overlay>
    </BannerContainer>
  );
};

export default HomeBanner;
