import React from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import FaqSection from "./section-components/faq";
import Footer from "../Home/Footer";

const Faq = () => {
  return (
    <div>
      <Navbar />
      <PageHeader headertitle="FAQ" />
      <FaqSection />
      <Footer />
    </div>
  );
};

export default Faq;
