import React, { Component } from "react";
import Avatar from "react-avatar";

import { Link, NavLink } from "react-router-dom";
import * as Actions from "../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import { formatCurrency } from "../utils/Helper";

function getRandomItemsFromArray(arr, numItems) {
  // Check if the number of items to retrieve is greater than the array length
  if (numItems >= arr.length) {
    return arr; // Return the entire array if numItems is greater or equal
  }

  const randomItems = [];
  const copyOfArray = [...arr]; // Create a copy of the original array to avoid modifying it

  for (let i = 0; i < numItems; i++) {
    const randomIndex = Math.floor(Math.random() * copyOfArray.length);
    const randomItem = copyOfArray.splice(randomIndex, 1)[0];
    randomItems.push(randomItem);
  }

  return randomItems;
}

const RecommendedProperties = (props) => {
  const dispatch = useDispatch();
  const properties = useSelector((state) => state.property.properties);

  let Customclass = "pd-top-60";

  return (
    <div className={"featured-area  " + Customclass}>
      <div className="container">
        <div className="section-title text-center">
          <h2 className="title">Recommended Properties</h2>
        </div>
        <div className="row justify-content-center">
          {/*  <div className="col-xl-6 col-lg-8">
            <div className="single-leading-feature">
              <div className="slf-overlay" />
              <div className="thumb">
                <img src={""} alt={imagealt} />
              </div>
              <div className="details">
                <h4 className="title readeal-top">
                  <Link to={data.firstitem.url}>{data.firstitem.title}</Link>
                </h4>
                <h5 className="price">{data.firstitem.price}</h5>
                <span>{data.firstitem.content}</span>
              </div>
            </div>
          </div> */}
          {properties &&
            getRandomItemsFromArray(properties, 3).map((item, i) => (
              <div key={i} className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                <div
                  className="single-feature"
                  style={{ position: "relative" }}
                >
                  {item.verified && (
                    <div
                      style={{
                        position: "absolute",
                        top: "0",
                        right: "0",
                        backgroundColor: "#22990080",
                        color: "white",
                        padding: "4px 8px",
                        borderRadius: "10px",
                        zIndex: "2", // Ensure the badge appears on top
                      }}
                    >
                      Verified
                    </div>
                  )}
                  <div className="thumb">
                    <img
                      src={item.mainImage}
                      alt={item.title}
                      style={{ aspectRatio: "3/2" }}
                    />
                  </div>
                  <div className="details" style={{ height: "230px" }}>
                    <a href="#" className="feature-logo">
                      <Avatar
                        name={item.agentId.fullName}
                        size="40"
                        round={true}
                      />
                    </a>
                    <p className="author">
                      <i className="fa fa-user" /> {item.agentId.fullName}
                    </p>
                    <h6
                      className="title readeal-top"
                      onClick={() => {
                        dispatch(Actions.setPropertyValue("property", item));
                        dispatch(Actions.loadPCatAction(item.id));
                      }}
                    >
                      <Link to={`/property-details/${item.slug}`}>
                        {item.title}
                      </Link>
                    </h6>
                    <h6 className="price">
                      {formatCurrency(
                        item.currentPrice ? item.currentPrice : 0
                      )}
                    </h6>
                    <del>
                      {" "}
                      {formatCurrency(item.oldPrice ? item.oldPrice : 0)}
                    </del>
                    <ul className="info-list">
                      {" "}
                      {/*   
                    {item.features.map((features, i) => (
                      <li key={i}>
                        <i className={features.icon} /> {features.title}
                      </li>
                    ))}*/}
                      <li>
                        <img src={"/assets/img/icons/7.png"} alt="img" />{" "}
                        {item.area}
                      </li>
                    </ul>
                    <ul className="contact-list">
                      <li>
                        <a className="phone" href="#">
                          <i className="fa fa-phone" />
                        </a>
                      </li>
                      <li>
                        <a className="message" href="#">
                          <img src={"/assets/img/icons/8.png"} alt="img" />
                        </a>
                      </li>

                      <li
                        className="readeal-top"
                        onClick={() => {
                          dispatch(Actions.setPropertyValue("property", item));
                          dispatch(Actions.loadPCatAction(item.id));
                        }}
                      >
                        <NavLink
                          className="btn btn-yellow"
                          to={`/property-details/${item.slug}`}
                        >
                          View Details
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default RecommendedProperties;
