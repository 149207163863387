import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import * as Actions from "../../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { notify } from "../../utils/Notification";
import styled from "styled-components";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};
const StyledH3 = styled.h3`
  font-size: 24px;
  color: #333;

  @media screen and (max-width: 640px) {
    font-size: 20px;
  }

  @media screen and (min-width: 641px) and (max-width: 1024px) {
    font-size: 22px;
  }
`;

// Style for the <span> element
const StyledSpan = styled.span`
  font-weight: bold;
  text-transform: uppercase;
  color: #007bff;

  @media screen and (max-width: 640px) {
    font-weight: normal;
    text-transform: none;
    color: #333;
  }
`;
export function formatPrice({ amount, currencyCode }) {
  const formatCurrency = new Intl.NumberFormat("en-NG", {
    style: "currency",
    currency: currencyCode,
  });

  return formatCurrency.format(amount);
}
const PropertyDetails = ({ props }) => {
  const dispatch = useDispatch();
  const [description, setDescription] = useState(null);
  const [fullName, setFullName] = useState(null);
  const [email, setEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [sortFeature, setSortFeature] = useState(null);
  const property = useSelector((state) => state.property.property);
  const sortedPCats = useSelector((state) => state.property.sortedPCats);
  console.log(sortedPCats && sortedPCats["res"]["propertyType"]);
  const sortDetails = (data) => {
    let count = 0;
    let added = [[], [], []];
    if (data && data.length > 0) {
      for (const d of data) {
        if (count === 2) {
          added[count] = [...added[count], d];
          count = 0;
        } else {
          added[count] = [...added[count], d];
          count++;
        }
      }
    }

    return added;
  };
  useEffect(() => {
    if (props.match.params.slug && !property.title) {
      dispatch(Actions.loadPropertyBySlug(props.match.params.slug));
    }
  }, [props.match.params.slug]);
  useEffect(() => {
    if (property) {
      dispatch(Actions.loadPCatAction(property.id));
      ///  dispatch(Actions.setPropertyValue("currentCity", property.city));
    }
  }, [property]);
  useEffect(() => {
    if (sortedPCats) {
      let x = sortDetails(sortedPCats && sortedPCats["res"]["features"]);
      setSortFeature(x);
    }
  }, [sortedPCats]);

  const bookAppointment = () => {
    let msg;
    if (!description) {
      msg = "Message Field is required";
    } else if (!fullName) {
      msg = "Fullname Field is required";
    } else if (!email) {
      msg = "Email Field is required";
    } else if (!phone) {
      msg = "Phone Field is required";
    } else {
      const data = {
        description,
        fullName,
        email,
        phone,
        property: property.id,
        agent: property.agentId.id,
      };
      console.log(data);
      dispatch(Actions.bookAppointment(data));
    }
    if (msg) {
      notify(msg);
    }
  };
  return (
    <div className="property-details-area">
      <div className="bg-gray pd-top-100 pd-bottom-90">
        <div className="container">
          <div className="row ">
            <div className="col-xl-9 col-lg-8">
              <div className="">
                <div className="item">
                  <div
                    className=""
                    style={{
                      width: "100%" /* Set the width of the container div */,
                      height: "500px" /* Set the height of the container div */,
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={property.mainImage}
                      alt={"imagealt"}
                      style={{
                        width:
                          "100%" /* Make the image width 100% of the container */,
                        height:
                          "auto" /* Automatically adjust the height to maintain aspect ratio */,
                        objectFit: "fill",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div
                className="property-details-slider-info"
                style={{ marginTop: "15px" }}
              >
                <StyledH3>
                  <StyledSpan>{property.title}</StyledSpan>
                </StyledH3>
                <span>
                  {formatPrice({
                    amount: property.currentPrice,
                    currencyCode: "NGN",
                  })}
                </span>
                {property.purchaseType === "SERVICE" ? (
                  <span style={{ marginLeft: 10 }}>per day</span>
                ) : property.purchaseType === "RENT" ? (
                  <span style={{ marginLeft: 10 }}>per year</span>
                ) : null}
                {/*  <del>$500</del> */}
              </div>
            </div>
            <div className="col-xl-3 col-lg-4">
              <div className="widget widget-owner-info mt-lg-0 mt-5">
                <div className="owner-info text-center">
                  {/*  <div className="thumb">
                    <img src={property.mainImage} alt={"imagealt"} />
                  </div> */}
                  <div className="details">
                    <h6>{property?.agentId?.fullName}</h6>
                    <span className="designation">Agent in Charge</span>
                    <p className="reviews">
                      <i className="fa fa-star" />
                      <span>{property.rating}</span>
                    </p>
                  </div>
                </div>
                {/*   <div className="contact">
                  <h6>Book an Appointment</h6>
                  <div className="rld-single-input">
                    <input
                      value={fullName}
                      onChange={(e) => {
                        setFullName(e.target.value);
                      }}
                      type="text"
                      placeholder="Full Name"
                    />
                  </div>
                  <div className="rld-single-input">
                    <input
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      type="email"
                      placeholder="Email"
                    />
                  </div>
                  <div className="rld-single-input">
                    <input
                      value={phone}
                      onChange={(e) => {
                        setPhone(e.target.value);
                      }}
                      type="text"
                      placeholder="Phone"
                    />
                  </div>
                  <div className="rld-single-input textarea">
                    <input
                      type="textarea"
                      value={description}
                      onChange={(e) => {
                        setDescription(e.target.value);
                      }}
                      placeholder="Messages"
                    />
                  </div>
                  <a
                    className="btn btn-yellow"
                    href="#"
                    onClick={bookAppointment}
                  >
                    Book Appointment
                  </a>
                </div> */}
                <div className="contact-info">
                  <h6 className="mb-3">Contact Info</h6>
                  <div className="media">
                    {/*  <div className="media-left">
                      <img src={property.mainImage} alt={"imagealt"} />
                    </div> */}
                    <div className="media-body">
                      <p>Address</p>
                      <span>Ikota Lagos</span>
                    </div>
                  </div>
                  <div className="media">
                    <div className="media-left">
                      <i className="fa fa-phone" />
                    </div>
                    <div className="media-body">
                      <p>Phone</p>
                      <span>{property?.agentId?.phone}</span>
                    </div>
                  </div>
                  <div className="media mb-0">
                    <div className="media-left">
                      <i className="fa fa-envelope" />
                    </div>
                    <div className="media-body">
                      <p>Email</p>
                      <span>{property?.agentId?.email}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row pd-top-90">
          <div className="col-lg-9">
            {/*   <div className="property-info mb-5">
              <div className="row">
                <div className="col-md-3 col-sm-6">
                  <div className="single-property-info">
                    <h5>Bedrooms</h5>
                    <p>
                      <i className="fa fa-bed" />
                      04
                    </p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="single-property-info">
                    <h5>Bathrooms</h5>
                    <p>
                      <i className="fa fa-bath" />
                      02
                    </p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="single-property-info">
                    <h5>Area</h5>
                    <p>
                      <img src={property.mainImage} alt={"imagealt"} />
                      1,038 sq. ft.
                    </p>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="single-property-info">
                    <h5>Parking</h5>
                    <p>
                      <i className="fa fa-car" />
                      01 Indoor
                    </p>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="property-news-single-card style-two border-bottom-yellow">
              <h4>Description</h4>
              <p>{property.description}</p>
              {/*            <a href="#">Read More</a> */}
            </div>
            {/*  <div className="property-news-single-card style-two border-bottom-yellow">
              <h4>Base Floor Plan</h4>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5621.1629504770535!2d-122.43633647504856!3d37.748515859182696!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80859a6d00690021%3A0x4a501367f076adff!2sSan%20Francisco%2C%20CA%2C%20USA!5e0!3m2!1sen!2sbd!4v1578304196576!5m2!1sen!2sbd"
                style={{ border: 0 }}
                allowFullScreen
              />
            </div> */}
            <div className="property-news-single-card border-bottom-yellow">
              <h4>Features</h4>
              <div className="row">
                <div className="col-sm-4">
                  <ul className="rld-list-style mb-3 mb-sm-0">
                    {sortFeature &&
                      sortFeature[0].map((sf, i) => {
                        return (
                          <li>
                            <i className="fa fa-check" /> {sf.value}
                          </li>
                        );
                      })}
                  </ul>
                </div>
                <div className="col-sm-4">
                  <ul className="rld-list-style mb-3 mb-sm-0">
                    {sortFeature &&
                      sortFeature[1].map((sf, i) => {
                        return (
                          <li>
                            <i className="fa fa-check" /> {sf.value}
                          </li>
                        );
                      })}
                  </ul>
                </div>
                <div className="col-sm-4">
                  <ul className="rld-list-style mb-3 mb-sm-0">
                    {sortFeature &&
                      sortFeature[2].map((sf, i) => {
                        return (
                          <li>
                            <i className="fa fa-check" /> {sf.value}
                          </li>
                        );
                      })}
                  </ul>
                </div>
              </div>
            </div>
            <div className="property-news-single-card border-bottom-yellow">
              <h4>More Images</h4>
              <div className="thumb">
                {sortedPCats && (
                  <Carousel responsive={responsive}>
                    {sortedPCats &&
                      sortedPCats["res"]["images"].map((im, i) => {
                        return (
                          <div>
                            <img src={im.value} />
                          </div>
                        );
                      })}
                  </Carousel>
                )}
                {/*      <Carousel responsive={responsive}>
                  {sortedPCats &&
                    sortedPCats["res"]["images"].map((im, i) => {
                      return (
                        <div>
                          <img src={im.value} />
                        </div>
                      );
                    })}
                </Carousel> */}
              </div>
            </div>
            {/*     <div className="property-news-single-card border-bottom-yellow pb-3">
              <h4>Facts and Features</h4>
              <div className="row">
                <div className="col-md-3 col-sm-6">
                  <div className="single-floor-list media">
                    <div className="media-left">
                      <i className="fa fa-bed" />
                    </div>
                    <div className="media-body">
                      <h6>Living Room</h6>
                      <p>20 x 16 sq feet</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="single-floor-list media">
                    <div className="media-left">
                      <i className="fa fa-car" />
                    </div>
                    <div className="media-body">
                      <h6>Garage</h6>
                      <p>20 x 16 sq feet</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="single-floor-list media">
                    <div className="media-left">
                      <img src={property.mainImage} alt={"imagealt"} />
                    </div>
                    <div className="media-body">
                      <h6>Dining Area</h6>
                      <p>20 x 16 sq feet</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="single-floor-list media">
                    <div className="media-left">
                      <img src={property.mainImage} alt={"imagealt"} />
                    </div>
                    <div className="media-body">
                      <h6>Dining Area</h6>
                      <p>20 x 16 sq feet</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="single-floor-list media">
                    <div className="media-left">
                      <i className="fa fa-bed" />
                    </div>
                    <div className="media-body">
                      <h6>Bedroom</h6>
                      <p>20 x 16 sq feet</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="single-floor-list media">
                    <div className="media-left">
                      <i className="fa fa-bath" />
                    </div>
                    <div className="media-body">
                      <h6>Bathroom</h6>
                      <p>20 x 16 sq feet</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="single-floor-list media">
                    <div className="media-left">
                      <img src={property.mainImage} alt={"imagealt"} />
                    </div>
                    <div className="media-body">
                      <h6>Gym Area</h6>
                      <p>20 x 16 sq feet</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-3 col-sm-6">
                  <div className="single-floor-list media">
                    <div className="media-left">
                      <img src={property.mainImage} alt={"imagealt"} />
                    </div>
                    <div className="media-body">
                      <h6>Gym Area</h6>
                      <p>20 x 16 sq feet</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="property-news-single-card border-bottom-yellow mb-0">
              <h4>3D Gallery</h4>
              <div className="thumb">
                <img src={property.mainImage} alt={"imagealt"} />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyDetails;
