import { combineReducers } from "redux";
import property from "./Property/property";
import pcat from "./PropertyCategory/pcat";

const reducers = combineReducers({
  pcat,
  property,
});

export default reducers;
