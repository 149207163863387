/* AnimatedArrow.css */
export const ArrowStyle = () => {
  return (
    <style jsx>
      {`
        @keyframes arrowAnimation {
          0% {
            transform: translateY(10px);
          }
          50% {
            transform: translateY(-20px);
          }
          100% {
            transform: translateY(10px);
          }
        }

        .animated-arrow .arrow-line,
        .animated-arrow .arrow-head {
          animation: arrowAnimation 1s infinite;
        }
      `}
    </style>
  );
}

