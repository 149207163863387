import { PCAT_FAILED, PCAT_LOADED, PCAT_LOADING, PCAT_SET } from "./types";

const initialState = {
  loading: false,
  types: [],
  subTypes: [],
  type: null,
  subType: null,
};

const pcat = (state = initialState, action) => {
  switch (action.type) {
    case PCAT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case PCAT_LOADED:
      return {
        ...state,
        loading: false,
        types: action.payload.types,
        subTypes: action.payload.subtypes,
      };
    case PCAT_SET:
      return {
        ...state,
        [action.key]: action.value,
      };

    case PCAT_FAILED:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
export default pcat;
