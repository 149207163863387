import * as Actions from "../redux/actions";
let cityImage = [
  "https://osmlimited.s3.us-west-2.amazonaws.com/city1.jpeg",
  "https://osmlimited.s3.us-west-2.amazonaws.com/city2.jpg",
  "https://osmlimited.s3.us-west-2.amazonaws.com/city3.jpeg",
  "https://osmlimited.s3.us-west-2.amazonaws.com/city4.jpg",
];
export const useSort = () => {
  const sortCityData = (data) => {
    let index = 0;
    let addedList = [];
    let cities = [];
    for (const d of data) {
      console.log(addedList, !addedList.includes(d.id));

      if (!addedList.includes(d.city)) {
        let x = { id: d.id, city: d.city, img: cityImage[index] };
        cities = [...cities, x];
        addedList = [...addedList, d.city];
        index++;
      }
    }
    return cities;
  };
  const propertyByCity = (data, city) => {
    let properties = [];
    for (const d of data) {
      if (Actions.string_to_slug(d.city) === city) {
        properties = [...properties, d];
      }
    }
    return properties;
  };
  const reactSelectModifier = (data) => {
    let body = [{ value: 0, label: "Select Property Preference" }];
    for (const d of data) {
      const x = { value: d.id, label: d.title };
      body = [...body, x];
    }
    return body;
  };
  return { sortCityData, propertyByCity, reactSelectModifier };
};
