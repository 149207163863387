import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  HashRouter,
  Route,
  Switch,
} from "react-router-dom";

import { Provider } from "react-redux";
import { configureStore } from "./redux/store";
import * as Actions from "./redux/actions";
import blogdata from "./data/blogdata.json";
import Singleblogdata from "./data/single-blogdata.json";
import Home from "./Home";
import HomeV1 from "./components/home-v1";
import HomeV2 from "./components/home-v2";
import HomeV3 from "./components/home-v3";
import HomeV4 from "./components/home-v4";
import Property from "./components/property";
import AvilableProperty from "./components/availavbe-property";
import PropertiesByCity from "./components/properties-by-city";
import RecentProperties from "./components/recent-properties";
import PropertyDetails from "./components/property-details";
import About from "./components/about";
import Advisor from "./components/advisor";
import Pricing from "./components/pricing";
import UserList from "./components/user-list";
import Registraion from "./components/registration";
import Error from "./components/error";
import Faq from "./components/faq";
import News from "./components/news";
import NewsDetails from "./components/news-details";
import Contact from "./components/contact";
import SearchMap from "./components/search-map";
import SearchGrid from "./components/search-grid";
import SearchList from "./components/search-list";
import AddNew from "./components/add-property";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./styles/tailwind.css";

const Root = () => {
  const store = configureStore();
  useEffect(() => {
    store.dispatch(Actions.loadProperties());
    store.dispatch(Actions.loadFeaturedProperties());
  }, []);
  return (
    <Router>
      <Provider store={store}>
        <div>
          <Switch>
            <Route exact path="/" component={Home} />
            {/* 
            <Route path="/home-v2" component={HomeV2} />
            <Route path="/home-v3" component={HomeV3} />
            <Route path="/home-v4" component={HomeV4} /> */}
            <Route path="/property" component={Property} />
            <Route path="/availavbe-property" component={AvilableProperty} />
            <Route
              path="/properties-by-city/:city"
              component={PropertiesByCity}
            />
            <Route exact path="/about" component={About} />
            <Route path="/recent-properties" component={RecentProperties} />
            <Route path="/property-details/:slug" component={PropertyDetails} />
            <Route path="/directory" component={SearchList} />
            <Route path="/faq" component={Faq} />
            <Route path="/contact" component={Contact} />
            <Route path="/search-grid" component={SearchGrid} />
            <Route path="/registration" component={Registraion} />
            {/* 
           
            <Route path="/advisor" component={Advisor} />
            <Route path="/pricing" component={Pricing} />
            <Route path="/user-list" component={UserList} />
      
            <Route path="/error" component={Error} />
          
            <Route path="/news" component={News} />
            <Route path="/news-details" component={NewsDetails} />
            
            <Route path="/search-map" component={SearchMap} />
         
            
            <Route path="/add-property" component={AddNew} /> */}
          </Switch>
          <ToastContainer />
        </div>
      </Provider>
    </Router>
  );
};

export default Root;

ReactDOM.render(<Root />, document.getElementById("realdeal"));
