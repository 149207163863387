export const PROPERTY_LOADING = "PROPERTY_LOADING";
export const PROPERTY_LOADED = "PROPERTY_LOADED";
export const PROPERTY_FAILED = "PROPERTY_FAILED";
export const PROPERTY_SET = "PROPERTY_SET";
export const PROPERTY_SEARCH = "PROPERTY_SEARCH";
export const PROPERTY_IMAGES = "PROPERTY_IMAGES";
export const PROPERTY_SLUG = "PROPERTY_SLUG";
export const PROPERTY_PCATS = "PROPERTY_PCATS";
export const PROPERTY_CAT_ADDED = "PROPERTY_CAT_ADDED";
export const PROPERTY_FEATURED = "PROPERTY_FEATURED";
export const PROPERTY_AREA = "PROPERTY_AREA";
