import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { useSort } from "../../utils/useSort";
import * as Actions from "../../redux/actions";
import { useSelector, useDispatch } from "react-redux";
import property from "../../redux/Property/property";
import { string_to_slug } from "../../redux/actions";

const Explore = ({ cities }) => {
  const dispatch = useDispatch();
  const properties = useSelector((state) => state.property.properties);
  const { propertyByCity } = useSort();

  return (
    <div className="explore-area pd-top-85">
      <div className="container">
        <div className="section-title text-center">
          <h2 className="title"> Explore Neighbourhoods </h2>
        </div>
        <div className="row">
          {cities.map((item, i) => (
            <div key={i} className="col-lg-3 col-sm-6">
              <div className="single-explore">
                <div className="thumb">
                  <img src={item.img} alt="explore" />
                  {/* <a href="#">
                    <i className="fa fa-paper-plane" />
                  </a> */}
                </div>
                <div className="details readeal-top">
                  <h4
                    onClick={() => {
                      console.log("df");
                      dispatch(
                        Actions.setPropertyValue(
                          "propertyCity",
                          propertyByCity(
                            properties,
                            Actions.string_to_slug(item.city)
                          )
                        )
                      );
                    }}
                  >
                    <NavLink
                      to={`/properties-by-city/${Actions.string_to_slug(
                        item.city
                      )}`}
                      style={{ textTransform: "capitalize" }}
                    >
                      {item.city}
                    </NavLink>
                  </h4>
                  {/* <ul className="list">
                    <li>
                      <img
                        src={publicUrl + "/assets/img/icons/1.png"}
                        alt="icona"
                      />
                      Listings: {item.lisgintnumber}
                    </li>
                    <li>
                      <i className="fa fa-usd" />
                      Price: ${item.price}
                    </li>
                  </ul> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Explore;
