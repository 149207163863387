import React, { useEffect } from "react";
import sectiondata from "../../data/sections.json";
import { NavLink } from "react-router-dom";
import parse from "html-react-parser";
import { useSelector, useDispatch } from "react-redux";
import * as Actions from "../../redux/actions";
import { useSort } from "../../utils/useSort";

const Property = ({ props }) => {
  const dispatch = useDispatch();
  const properties = useSelector((state) => state.property.properties);
  const { propertyByCity } = useSort();
  const propertyCity = useSelector((state) => state.property.propertyCity);

  useEffect(() => {
    if (!properties) {
      dispatch(Actions.loadProperties());
    } else {
      dispatch(
        Actions.setPropertyValue(
          "propertyCity",
          propertyByCity(
            properties,
            Actions.string_to_slug(props.match.params.city)
          )
        )
      );
    }
  }, [properties]);

  return (
    <div className="properties-area pd-top-92">
      <div className="container">
        <div className="section-title">
          <h2 className="title">{`Properties in ${
            propertyCity && propertyCity[0].city
          }`}</h2>
        </div>
        <div className="row">
          {propertyCity &&
            propertyCity.map((item, i) => (
              <div key={i} className="col-lg-3 col-sm-6 ">
                <div className="single-feature">
                  <div className="thumb">
                    <img
                      src={item.mainImage}
                      alt="img"
                      style={{ width: "350px", height: "240px" }}
                    />
                  </div>
                  <div className="details">
                    <p className="author">
                      <i className="fa fa-user" /> {item.agentId.fullName}
                    </p>
                    <h6
                      className="title readeal-top"
                      onClick={() => {
                        dispatch(Actions.setPropertyValue("property", item));
                      }}
                    >
                      <NavLink to={`/property-details/${item.slug}`}>
                        {item.title}
                      </NavLink>
                    </h6>
                    <h6 className="price">
                      ₦{Actions.numberWithCommas(item.price)}
                    </h6>
                    <del>{item.olderprice}</del>
                    {/*  <ul className="info-list">
                    {item.features.map((features, i) => (
                      <li key={i}>
                        <i className={features.icon} /> {features.title}
                      </li>
                    ))}
                    <li>
                      <img
                        src={publicUrl + "/assets/img/icons/7.png"}
                        alt={""}
                      />{" "}
                      {item.area}{" "}
                    </li>
                  </ul> */}
                    <ul className="contact-list">
                      <li>
                        <a className="phone" href="#">
                          <i className="fa fa-phone" />
                        </a>
                      </li>
                      <li>
                        <a className="message" href="#">
                          <img src={"/assets/img/icons/8.png"} alt="img" />
                        </a>
                      </li>
                      <li
                        className="readeal-top"
                        onClick={() => {
                          dispatch(Actions.setPropertyValue("property", item));
                        }}
                      >
                        <NavLink
                          className="btn btn-yellow"
                          to={`/property-details/${item.slug}`}
                        >
                          View Details
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Property;
