import React from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import PropertyDetailsSection from "./section-components/property-details";
import RecomandProperties from "../Home/RecommendedProperties";
import Footer from "./global-components/footer";
import { useSelector } from "react-redux";

const PropertyDetails = (props) => {
  const property = useSelector((state) => state.property.property);
  return (
    <div>
      <Navbar />
      <PageHeader headertitle={`Property Details (${property.title})`} />
      <PropertyDetailsSection props={props} />
      <RecomandProperties />
      <Footer />
    </div>
  );
};

export default PropertyDetails;
