import {
  PROPERTY_FAILED,
  PROPERTY_LOADED,
  PROPERTY_LOADING,
  PROPERTY_SEARCH,
  PROPERTY_SET,
  PROPERTY_IMAGES,
  PROPERTY_SLUG,
  PROPERTY_PCATS,
  PROPERTY_FEATURED,
  PROPERTY_AREA,
} from "./types";

const initialState = {
  loading: false,
  properties: null,
  property: {},
  images: [],
  propertyList: null,
  propertyCity: null,
  currentCity: null,
  propertyCats: null,
  sortedPCats: null,
  featuredProperties: [],
  propertyArea: [],
};
const sortDetails = (data) => {
  let res = {};
  let added = [];
  for (const d of data) {
    let x = d.propertyCat.title;
    if (added.includes(d.propertyCat.title)) {
      res[x] = [...res[x], d];
    } else {
      res[x] = [d];
      added.push(x);
    }
  }
  return { res, added };
};
const property = (state = initialState, action) => {
  switch (action.type) {
    case PROPERTY_LOADING:
      return {
        ...state,
        loading: true,
      };
    case PROPERTY_SLUG:
      return {
        ...state,
        loading: false,
        property: action.payload,
      };
    case PROPERTY_LOADED:
      return {
        ...state,
        loading: false,
        properties: action.payload,
        propertyList: action.payload,
      };
    case PROPERTY_IMAGES:
      return {
        ...state,
        loading: false,
        images: action.payload,
      };
    case PROPERTY_SET:
      return {
        ...state,
        loading: false,
        [action.key]: action.value,
      };
    case PROPERTY_SEARCH:
      return {
        ...state,
        loading: false,
        propertyList: action.payload,
      };
    case PROPERTY_FAILED:
      return {
        ...state,
        loading: false,
      };
    case PROPERTY_PCATS:
      return {
        ...state,
        loading: false,
        propertyCats: action.payload,
        sortedPCats: sortDetails(action.payload),
      };
    case PROPERTY_FEATURED:
      return {
        ...state,
        loading: false,
        featuredProperties: action.payload,
      };
    case PROPERTY_AREA:
      return {
        ...state,
        loading: false,
        propertyList: action.payload,
      };
    default:
      return state;
  }
};
export default property;
