import {
  PROPERTY_FAILED,
  PROPERTY_LOADED,
  PROPERTY_LOADING,
  PROPERTY_SET,
  PROPERTY_SEARCH,
  PROPERTY_IMAGES,
  PROPERTY_SLUG,
  PROPERTY_CAT_ADDED,
  PROPERTY_PCATS,
  PROPERTY_FEATURED,
  PROPERTY_AREA,
} from "./types";
import Axios from "axios";
export const URL = "https://osmpartnersltd.com";
//export const URL = "";

export const loadProperties = () => (dispatch) => {
  dispatch({ type: PROPERTY_LOADING });
  Axios.get(`${URL}/osm/api/v1/property/`)
    .then((res) => {
      dispatch({ type: PROPERTY_LOADED, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: PROPERTY_FAILED, payload: err.response });
    });
};
export const loadFeaturedProperties = () => (dispatch) => {
  dispatch({ type: PROPERTY_LOADING });
  Axios.get(`${URL}/osm/api/v1/property/featured`)
    .then((res) => {
      dispatch({ type: PROPERTY_FEATURED, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: PROPERTY_FAILED, payload: err.response });
    });
};
export const loadPropertyArea = (area) => (dispatch) => {
  dispatch({ type: PROPERTY_LOADING });
  Axios.get(`${URL}/osm/api/v1/property/area/${area}`)
    .then((res) => {
      dispatch({ type: PROPERTY_AREA, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: PROPERTY_FAILED, payload: err.response });
    });
};

export const setPropertyValue = (key, value) => (dispatch) => {
  dispatch({ type: PROPERTY_SET, key, value });
};

export const searchProperty = (search, func) => (dispatch) => {
  dispatch({ type: PROPERTY_LOADING });
  Axios.get(`${URL}/osm/api/v1/property/search?search=` + search)
    .then((res) => {
      dispatch({ type: PROPERTY_SEARCH, payload: res.data });
      func();
    })
    .catch((err) => {
      dispatch({ type: PROPERTY_FAILED, payload: err.response });
    });
};

export const loadPropertyImages = (id) => (dispatch, getState) => {
  dispatch({ type: PROPERTY_LOADING });
  Axios.get(`${URL}/osm/api/v1/property/image/${id}`)
    .then((res) => {
      dispatch({ type: PROPERTY_IMAGES, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: PROPERTY_FAILED, payload: err.response });
    });
};

export const loadPropertyBySlug = (slug) => (dispatch, getState) => {
  dispatch({ type: PROPERTY_LOADING });
  Axios.get(`${URL}/osm/api/v1/property/slug/${slug}`)
    .then((res) => {
      dispatch({ type: PROPERTY_SLUG, payload: res.data });
    })
    .catch((err) => {
      dispatch({ type: PROPERTY_FAILED, payload: err.response });
    });
};
export const loadPCatAction = (property) => (dispatch, getState) => {
  dispatch({ type: PROPERTY_LOADING });
  Axios.get(`${URL}/osm/api/v1/property/cat/` + property)
    .then((res) => {
      dispatch({ type: PROPERTY_PCATS, payload: res.data });
    })
    .catch((err) => {
      console.log(err);
      dispatch({ type: PROPERTY_FAILED, payload: err.response });
    });
};

export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export function string_to_slug(str) {
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  var to = "aaaaeeeeiiiioooouuuunc------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
}
export function nFormatter(num, digits) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
}
