import React, { useState, useEffect } from "react";
import sectiondata from "../../data/sections.json";
import parse from "html-react-parser";
import { Link, Redirect, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { useSort } from "../../utils/useSort";
import { notify } from "../../utils/Notification";
import * as Actions from "../../redux/actions";
import { formatCurrency } from "../../utils/Helper";

const SearchList = () => {
  const dispatch = useDispatch();

  const { reactSelectModifier } = useSort();

  const [city, setCity] = useState("");
  const [period, setPeriod] = useState(0);
  const [typeModified, setTypeModifid] = useState(null);
  const types = useSelector((state) => state.pcat.types);
  const type = useSelector((state) => state.pcat.type);
  const subType = useSelector((state) => state.pcat.subType);
  const subTypes = useSelector((state) => state.pcat.subTypes);
  const propertyList = useSelector((state) => state.property.propertyList);

  useEffect(() => {
    setTypeModifid(reactSelectModifier(types));
  }, [types]);

  const searchProperty = (e) => {
    e.preventDefault();
    let msg;
    if (!city) {
      msg = "Search box is empty";
    } else {
      dispatch(Actions.searchProperty(city));
      return <Redirect to="/directory" />;
    }

    if (msg) {
      notify(msg);
    }
  };
  let data = sectiondata.searchlist;
  return (
    <div className="search-page-wrap pd-top-100 pd-bottom-70">
      <div className="search-container">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-8">
              <div className="row mb-3">
                <div className="col-md-12 col-sm-12">
                  <h6 className="filter-title mt-3 mb-lg-0">
                    {!propertyList ? 0 : propertyList.length} Properties
                  </h6>
                </div>
                {/*      <div className="col-md-3 col-sm-4">
                  <div className="rld-single-select">
                    <select className="select single-select">
                      <option value={1}>Tile View</option>
                      <option value={2}>Tile View 1</option>
                      <option value={3}>Tile View 2</option>
                      <option value={3}>Tile View 3</option>
                    </select>
                  </div>
                </div> */}
              </div>

              {propertyList ? (
                propertyList.map((item, i) => (
                  <div key={i} className="single-feature style-two">
                    <div className="thumb">
                      <img height={200} src={item.mainImage} alt="img" />
                    </div>
                    <div className="details">
                      <div className="details-wrap">
                        {/*  <a href="#" className="feature-logo">
                        <img src={publicUrl + item.icon} alt={imagealt} />
                      </a> */}
                        <p className="author">
                          <i className="fa fa-user" /> {item.agentId.fullName}
                        </p>
                        <h6
                          className="title readeal-top"
                          onClick={() => {
                            dispatch(
                              Actions.setPropertyValue("property", item)
                            );
                          }}
                        >
                          <NavLink to={`/property-details/${item.slug}`}>
                            {item.title}
                          </NavLink>
                        </h6>
                        <h6 className="price">
                          {formatCurrency(item.currentPrice)}
                        </h6>
                        <del> {formatCurrency(item.oldPrice)}</del>
                        <ul className="info-list">
                          {/*  
                        {item.features.map((features, i) => (
                          <li key={i}>
                            <i className={features.icon} /> {features.title}
                          </li>
                        ))}
                        */}
                          <li>
                            <img
                              src={"/assets/img/icons/7.png"}
                              alt={item.title}
                            />{" "}
                            {item.area}{" "}
                          </li>
                        </ul>
                        <ul className="contact-list">
                          <li>
                            <a className="phone" href="#">
                              <i className="fa fa-phone" />
                            </a>
                          </li>
                          <li>
                            <a className="message" href="#">
                              <img src={"/assets/img/icons/8.png"} alt="img" />
                            </a>
                          </li>
                          <li
                            className="readeal-top"
                            onClick={() => {
                              dispatch(
                                Actions.setPropertyValue("property", item)
                              );
                            }}
                          >
                            <NavLink
                              to={`/property-details/${item.slug}`}
                              className="btn btn-yellow"
                            >
                              View Details
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div>No Properties</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchList;
