import React, { useEffect } from "react";
import Navbar from "./global-components/navbar";
import PageHeader from "./global-components/page-header";
import SearchListSection from "./section-components/search-list";
import Footer from "./global-components/footer";
import SearchBar from "../Home/SearchBar";
import { styled } from "styled-components";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import * as Actions from "../redux/actions";
import queryString from "query-string";

const SearchList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  // Access individual query parameters s
  const area = queryParams.area;

  useEffect(() => {
    if (area) {
      dispatch(Actions.loadPropertyArea(area));
    }
  }, [area]);
  return (
    <div>
      <Navbar />
      <PageHeader headertitle="Property Search" subheader="Apartment rent" />

      <SearchListSection />
      <Footer />
    </div>
  );
};

export default SearchList;
