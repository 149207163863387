import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Navbar from "../components/global-components/navbar";
import Bannerv2 from "./Banner";
import Explore from "../components/section-components/explore";
import FeaturedProperties from "./FeaturedProperties";
import Ads from "../components/section-components/ads";
import PropertiesByCities from "../components/section-components/properties-by-cities";
import RecentProperties from "../components/section-components/recent-properties";
import FeaturedPorject from "../components/section-components/featured-project";
import WhyChooseUs from "../components/section-components/why-choose-us";
import OurPartner from "../components/section-components/our-partner";
import Footer from "./Footer";
import { useSort } from "../utils/useSort";
import Client from "../components/section-components/client";
import RecommendedProperties from "./RecommendedProperties";
const Home_V1 = () => {
  const properties = useSelector((state) => state.property.properties);
  const { sortCityData } = useSort();
  const [cities, setCities] = useState([]);
  useEffect(() => {
    if (properties) {
      setCities(sortCityData(properties));
    }
  }, [properties]);

  return (
    <div>
      <Navbar />
      <Bannerv2 />
      <WhyChooseUs />
      <FeaturedProperties />
      {/*  <Ads /> */}
      <RecommendedProperties />

      {/* 
      <Client PaddingTop="pd-top-60" PaddingBottom="0" /> */}
      {/*   
      <Explore cities={cities} />
     */}
      {/*  */}
      {/*  <PropertiesByCities cities={cities} /> */}
      {/*   <RecentProperties /> */}
      {/*  <FeaturedPorject /> */}
      {/*  
      <OurPartner /> */}
      <Footer />
    </div>
  );
};

export default Home_V1;
