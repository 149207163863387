import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as Actions from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { useSort } from "../../utils/useSort";
import { notify } from "../../utils/Notification";
import { Redirect } from "react-router-dom";
import SearchBar from "../../Home/SearchBar";
import { styled } from "styled-components";

const ResponsiveContainer = styled.div`
  width: 90%; /* Default size for screens less than md */
  margin: 0 auto; /* Center the content horizontally */

  @media (min-width: 768px) {
    width: 60%; /* Adjust size for screens greater than md */
  }
`;
const PageTitle = styled.h3`
  font-size: 44px;
  color: #fff;

  @media screen and (max-width: 640px) {
    font-size: 20px;
  }

  @media screen and (min-width: 641px) and (max-width: 1024px) {
    font-size: 22px;
  }
`;
const Page_header = (props) => {
  const $ = window.$;
  const dispatch = useDispatch();
  const { reactSelectModifier } = useSort();

  const [city, setCity] = useState("");
  const [period, setPeriod] = useState(0);
  const [typeModified, setTypeModifid] = useState([]);
  const types = useSelector((state) => state.pcat.types);
  const type = useSelector((state) => state.pcat.type);
  const subType = useSelector((state) => state.pcat.subType);
  const subTypes = useSelector((state) => state.pcat.subTypes);
  const loading = useSelector((state) => state.property.loading);

  let HeaderTitle = props.headertitle;
  let publicUrl = process.env.PUBLIC_URL + "/";
  let Subheader = props.subheader ? props.subheader : HeaderTitle;
  const inlineStyle = {
    backgroundImage: "url(/assets/img/upload/djsjfskfkj1641194134567.jpeg)",
  };
  useEffect(() => {
    setTypeModifid(reactSelectModifier(types));
  }, [types]);

  const searchProperty = () => {
    let msg;
    if (!city) {
      msg = "Search box is empty";
    } else {
      dispatch(Actions.searchProperty(city));

      return <Redirect to="/directory" />;
    }

    if (msg) {
      notify(msg);
    }
  };

  return (
    <>
      <div className="breadcrumb-area jarallax" style={inlineStyle}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="breadcrumb-inner">
                <PageTitle>{HeaderTitle}</PageTitle>
                {/*  <ul className="page-list">
                      <li><Link to="/">Home</Link></li>
                      <li>{ Subheader }</li>
                    </ul> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ResponsiveContainer>
        <SearchBar
          onClick={() => searchProperty()}
          label={loading ? "Please wait" : "Search"}
          city={city}
          setCity={setCity}
        />
      </ResponsiveContainer>
    </>
  );
};

export default Page_header;
