import React, { Component } from "react";
import { Link } from "react-router-dom";

import styled from "styled-components";
import favicon from "../../favicon.png";

class Navbar extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imgattr = "logo";
    let anchor = "#";
    const ResponsiveImage = styled.img`
      width: 80px; /* Default width */

      @media screen and (max-width: 640px) {
        width: 80px; /* Adjust width for smaller screens */
      }

      @media screen and (min-width: 641px) and (max-width: 1024px) {
        width: 80px; /* Adjust width for medium-sized screens */
      }
    `;

    const LogoLink = styled(Link)`
      display: inline-block; /* Ensure the Link behaves like a block element */
    `;
    const Navbar = styled.ul`
      list-style: none;
      padding: 0;
      display: flex;
      align-items: center;
    `;

    const NavItem = styled.li`
      margin-right: 20px;
      font-size: 16px;
      color: #fff;
      text-transform: uppercase;

      /* Apply styles on hover */
      &:hover {
        color: #333;
      }
    `;

    const StyledLink = styled(Link)`
      text-decoration: none;
      color: inherit;
    `;
    return (
      <div>
        <div className="navbar-area">
          <nav className="navbar navbar-area navbar-expand-lg">
            <div className="container nav-container">
              <div className="responsive-mobile-menu">
                <button
                  className="menu toggle-btn d-block d-lg-none"
                  data-toggle="collapse"
                  data-target="#realdeal_main_menu"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="icon-left" />
                  <span className="icon-right" />
                </button>
              </div>
              <div className="logo readeal-top">
                <LogoLink to="/">
                  <ResponsiveImage src={favicon} alt="logo" />
                </LogoLink>
              </div>
              {/*  <div className="nav-right-part nav-right-part-mobile">
                <Link className="btn btn-yellow" to="/add-property">
                  ADD LISTING{" "}
                  <span className="right">
                    <i className="la la-plus" />
                  </span>
                </Link>
              </div> */}
              <div className="collapse navbar-collapse" id="realdeal_main_menu">
                <ul
                  className="navbar-nav menu-open readeal-top"
                  // style={{ backgroundColor: "#c19a65" }}
                >
                  <NavItem>
                    <StyledLink to="/">Home</StyledLink>
                  </NavItem>
                  <NavItem>
                    <StyledLink to="/directory">Directory</StyledLink>
                  </NavItem>
                  <NavItem>
                    <StyledLink to="/about">About Us</StyledLink>
                  </NavItem>
                  <NavItem>
                    <StyledLink to="/faq">FAQ</StyledLink>
                  </NavItem>
                  <NavItem>
                    <StyledLink to="/contact">Contact Us</StyledLink>
                  </NavItem>

                  {/*  <li className="menu-item-has-children">
                    <a href="#">Property</a>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/property">Property</Link>
                      </li>
                      <li>
                        <Link to="/availavbe-property">
                          Propertys Availavbe
                        </Link>
                      </li>
                      <li>
                        <Link to="/properties-by-city">Property By City</Link>
                      </li>
                      <li>
                        <Link to="/recent-properties">Property Recenty</Link>
                      </li>
                      <li>
                        <Link to="/property-details">Property Details</Link>
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item-has-children">
                    <a href="#">Pages</a>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/About">About</Link>
                      </li>
                      <li>
                        <Link to="/advisor">Advisor</Link>
                      </li>
                      <li>
                        <Link to="/directory">Search List</Link>
                      </li>
                      <li>
                        <Link to="/search-grid">Search Grid</Link>
                      </li>
                      <li>
                        <Link to="/faq">FAQ</Link>
                      </li>
                      <li>
                        <Link to="/pricing">Pricing</Link>
                      </li>
                      <li>
                        <Link to="/user-list">User List</Link>
                      </li>
                      <li>
                        <Link to="/Registration">Registration</Link>
                      </li>
                      <li>
                        <Link to="/error">404</Link>
                      </li>
                    </ul>
                  </li> */}
                  {/*    <li className="menu-item-has-children">
                    <a href="#">News</a>
                    <ul className="sub-menu">
                      <li>
                        <Link to="/news">News</Link>
                      </li>
                      <li>
                        <Link to="/news-details">News Details</Link>
                      </li>
                    </ul>
                  </li> */}
                </ul>
              </div>
              {/*    <div className="nav-right-part nav-right-part-desktop readeal-top">
                <Link className="btn btn-yellow" to="/add-property">
                  ADD LISTING{" "}
                  <span className="right">
                    <i className="la la-plus" />
                  </span>
                </Link>
              </div> */}
            </div>
          </nav>
        </div>
      </div>
    );
  }
}

export default Navbar;
