import React from "react";
import styled from "styled-components";

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.5rem;
  background-color: none;
  width: 100%;
  border-radius: 1rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  transition: box-shadow 0.3s, transform 0.3s;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    padding: 0.2rem;
  }

  &:hover {
    box-shadow: 0 6px 10px -1px rgba(0, 0, 0, 0.1),
      0 3px 6px -1px rgba(0, 0, 0, 0.06);
    transform: scale(1.05);
  }
`;
const SearchInputContainer = styled.div`
  display: flex;
  background-color: #f0f4f7;
  padding: 1rem;
  width: 80%;
  border-radius: 0.5rem;
  margin-right: 1.5rem;

  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 1.5rem;
  }
`;
const SearchIcon = styled.svg`
  height: 1.5rem;
  width: 1.5rem;
  opacity: 0.7;
`;

const SearchInput = styled.input`
  background-color: #f0f4f7;
  outline: none;
  border: none;
  width: 100%;
`;

const CategoriesContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.75rem;
  border-radius: 0.5rem;
  font-weight: 600;
  cursor: pointer;
  color: #6b7280;
`;

const CategoriesIcon = styled.svg`
  height: 1.5rem;
  width: 1.5rem;
  margin-right: 0.5rem;
`;

const SearchButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #c19a65;
  padding: 0.75rem 1.5rem;
  border-radius: 0.5rem;
  font-weight: 600;
  color: white;
  cursor: pointer;
  transition: box-shadow 3s;
  width: 20%;

  @media (max-width: 768px) {
    width: 100%;
    display: hidden;
  }

  &:hover {
    box-shadow: 0 4px 6px -1px rgba(239, 68, 68, 0.5);
  }
`;
const MainSearch = styled.div`
  margin-top: 2rem;
`;

const SingleInput = styled.div`
  display: flex;
  align-items: center;
  background-color: #ffffff77;
  color: #000000;
  border-radius: 5px;
  padding: 0.5rem 1rem;
`;

const SearchBar = ({ label, onClick, city, setCity }) => {
  return (
    <MainSearch>
      <SingleInput>
        <SearchContainer>
          <SearchInputContainer>
            <SearchIcon
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 opacity-30"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              />
            </SearchIcon>
            <SearchInput
              className="bg-gray-100 outline-none"
              type="text"
              placeholder="Search city, state, address, property type..."
              value={city}
              onChange={(e) => {
                setCity(e.target.value);
              }}
            />
          </SearchInputContainer>
          {/*   <CategoriesContainer>
        <CategoriesIcon
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="#ffffff"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M19 9l-7 7-7-7"
          />
        </CategoriesIcon>
        <span style={{ color: "#ffffff" }}>All categories</span>
      </CategoriesContainer> */}
          <SearchButton onClick={onClick}>
            <span>{label}</span>
          </SearchButton>
        </SearchContainer>
      </SingleInput>
    </MainSearch>
  );
};

export default SearchBar;
