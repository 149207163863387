import React, { Component } from "react";
import sectiondata from "../../data/sections.json";
import parse from "html-react-parser";

class AboutUs extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imagealt = "image";
    let data = sectiondata.aboutus;

    return (
      <div className="about-area pd-bottom-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mb-4 mb-lg-0">
              <div className="shape-image-list-wrap">
                <div className="shape-image-list left-top">
                  <img
                    className="shadow-img"
                    src={"/assets/img/upload/aboutus.jpeg"}
                    alt={imagealt}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 align-self-center">
              <div className="section-title pd-left mb-0">
                <h5 className="sub-title">{data.subtitle}</h5>
                <h2 className="title"></h2>
                <p>
                  At OSM Partners Ltd we are passionate about the real estate
                  market. We want to provide you with your best options. Our
                  goal is to provide a safe means acquiring properties and lands
                  in Nigeria. We look forward to earning your trust and respect
                  through our top-notch service and extensive real estate
                  knowledge.
                </p>
                {/*  <a className="btn btn-yellow" href={data.url}>
                  {data.btn_text}
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AboutUs;
